import axios from 'axios';
import {API_CONFIG} from "../config/api-config";
import {API_TOKEN_CONFIG} from "../config/api-config";
import dynamoClient from "./dynamo-api";


const DataParams = {
    apiUrl: API_CONFIG,
    apiToken: API_TOKEN_CONFIG
};

const username = 'getup';
const password = '4golKs(/:!3)n\\txNFUQ';

const token = username+":"+password;
const encodedToken = Buffer.from(token).toString('base64');

const authHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    'Authorization': 'Basic '+ encodedToken
};

const submitBankingDetails = (bankingData) => {
    let config = {
        headers: authHeaders
    };
    return axios.post(DataParams.apiUrl + 'banking/capture',bankingData,config);
}




const getBankNameAndCodesInfo = () => {
    return axios({
        method: 'get',
        url: 'https://d1auaa2ifhowoy.cloudfront.net/getup-api-gateway/bank.json',
        headers:  {"Content-Type": "application/json","Access-Control-Allow-Origin": "*"}
    });
}

const fetchDocument = async (lexId) => {
    let document =  await dynamoClient.getDocumentById(lexId);
    return document
}

export default {
    fetchDocument,
    submitBankingDetails,
    getBankNameAndCodesInfo
};

